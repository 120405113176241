<template>
  <div>
    <div class="bg_wra">
      <h1>行业解决方案</h1>
      <h4>寓信行业解决方案致力于应用智能技术，助力企业实现数字化转 <br>型，重塑企业核心竞争力</h4>
    </div>
    <div class="solution_part">
      <h2>智慧租赁解决方案</h2>
      <h4>寓信智慧租赁解决方案为企业搭建数字化管理平台，在长租公寓的四大领域，为企业提 <br>供标准化的业务管理方案，促进业务创新，提升客户满意度，打造企业核心竞争力。</h4>
      <solution-item></solution-item>
<!--      <a-row type="flex" justify="space-around" class="type-list">-->
<!--        <a-col class="sub-part" :span="5" @click="lookDetails('rencai')">人才公寓</a-col>-->
<!--        <a-col class="sub-part" :span="5" @click="lookDetails('shangye')">商业公寓</a-col>-->
<!--        <a-col class="sub-part" :span="5" @click="lookDetails('gongzu')">公租房</a-col>-->
<!--        <a-col class="sub-part" :span="5" @click="lookDetails('lanling')">蓝领公寓</a-col>-->
<!--      </a-row>-->
    </div>
    <div class="container">
      <div class="title_con">
        <h1>技术实施解决方案</h1>
        <h4>根据需求自定义采购，全面助力长短租公寓企业信息化升级</h4>
      </div>
      <solution-component class="pc_solution"></solution-component>
    </div>
    <div class="mobile_solution">
      <div class="item">
        <img src="../assets/image/homebg_1.png" alt="">
        <h3>云部署解决方案</h3>
        <p>依托强大的云计算能力，为企业提供一站式服务，无需下载安装，无需适配不同的软硬件平台，即可轻松使用标准版系统，实现高效管理。适用于中型公寓企业。</p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_3.png" alt="">
        <h3>私有化解决方案</h3>
        <p>依托企业自有平台，根据企业需求将其部署到指定的云服务器，包括管理系统、官网、官微、小程序等，形成自己的数据库，实现特色化管理。适用于中大型公寓企业。</p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_5.png" alt="">
        <h3>轻定制解决方案</h3>
        <p>基于私有化解决方案增加定制服务，根据企业需求可对数据报表、支付系统、智能硬件等模块进行单独定制，适用于有清晰业务模式的品牌公寓企业。</p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_7.png" alt="">
        <h3>全定制解决方案</h3>
        <p>组建行业资深专家组，深入企业内部全面了解业务场景，针对企业自身的业务痛点，打造专属化的解决方案，打造成企业特有的品牌。适用于大型或集团型企业。</p>
      </div>
    </div>
  </div>
</template>

<script>
import SolutionComponent from "../components/SolutionComponent";
import SolutionItem from "../components/SolutionItem";
export default {
  name: "Solution",
  components:{
    SolutionComponent,
    SolutionItem
  },
  data(){
    return{

    }
  },
  methods:{
    lookDetails(name){
      this.$router.push({name:'solutionDetails',params:{name:name}})
    }
  }
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  background: url("../assets/image/solution/solution_bg.png") no-repeat center;
  background-size: cover;
  h1{
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    padding-top: 170px;
  }
  h4{
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
  }
}
.solution_part{
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  text-align: center;
  .type-list{
    padding: 0 50px;
  }
  h2{
    font-weight: 700;
  }
  h4{
    margin-bottom: 2em;
  }
  .sub-part{
    margin-top: 30px;
    height: 202px;
    background-color: #F7F7F7;
    font-size: 22px;
    padding-top: 130px;
    background-image: url("../assets/image/solution/rencai_dark.png");
    background-repeat: no-repeat;
    background-position: center 35%;
    cursor: pointer;
    border-bottom: solid 12px transparent;
    &:hover{
      background-image: url("../assets/image/solution/rencai_light.png");
      border-bottom: solid 12px #0284EF;
    }
  }
  .sub-part:nth-child(2){
    background-image: url("../assets/image/solution/shangye_dark.png");
    &:hover{
      background-image: url("../assets/image/solution/shangye_light.png");
    }
  }
  .sub-part:nth-child(3){
    background-image: url("../assets/image/solution/gongzu_dark.png");
    &:hover{
      background-image: url("../assets/image/solution/gongzu_light.png");
    }
  }
  .sub-part:nth-child(4){
    background-image: url("../assets/image/solution/lanling_dark.png");
    &:hover{
      background-image: url("../assets/image/solution/lanling_light.png");
    }
  }
}
.container{
  height: 680px;
  padding: 120px 0;
  background: url("../assets/image/bg_solution.png") no-repeat center;
  background-size: cover;
  .title_con{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    h1{
      color: #fff;
      font-size: 30px;
    }
    h4{
      color: #FAFAFA;
      font-size: 16px;
      margin-bottom: 80px;
    }
  }
}
.mobile_solution{
  display: none;
}
@media screen and (max-width: 768px){
  .bg_wra{
    height: 180px;
    padding: 0 10px;
    h1{
      width: 100%;
      font-size: 20px;
      padding-top: 70px;
    }
    h4{
      width: 100%;
      font-size: 12px;
    }
  }
  .solution_part{
    width: 100%;
    padding: 40px 0;
    .type-list{
      padding: 0 20px;
    }
    .sub-part{
      width: 42%;
      height: 130px;
      font-size: 18px;
      padding-top: 90px;
    }
    h4{
      width: 150%;
      transform-origin: left;
      transform: scale(0.66);
    }
  }
  .container{
    height: 200px;
    background-position: top;
    padding: 70px 0;
    .title_con{
      width: 100vw;
      h1{
        font-size: 20px;
      }
      h4{
        font-size: 12px;
      }
    }
    .pc_solution{
      display: none;
    }
  }
  .mobile_solution{
    display: block;
    .item{
      padding: 20px;
      img{
        width: 100%;
      }
      h3{
        font-size: 20px;
        color: #0167FF;
        margin-top: 20px;
      }
      p{
        color: #999;
      }
    }
  }
}
</style>